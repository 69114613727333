import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import theme from './theme';
import { Stages } from './types';

// https://kentcdodds.com/blog/how-to-use-react-context-effectively

type Action =
	| { type: 'SELECT_STAGE'; payload: Stages }
	| { type: 'OPEN_MENU_DRAWER' }
	| { type: 'CLOSE_MENU_DRAWER' };
type Dispatch = (action: Action) => void;
type State = {
	selectedStage: Stages;
	menuDrawerOpen: boolean;
};
type HabitOptionsProviderProps = { children: React.ReactNode };

const HabitOptionsContext = React.createContext<
	{ state: State; dispatch: Dispatch } | undefined
>(undefined);

function optionsReducer(state: State, action: Action): State {
	switch (action.type) {
		case 'SELECT_STAGE': {
			return { ...state, selectedStage: action.payload };
		}
		case 'OPEN_MENU_DRAWER': {
			return { ...state, menuDrawerOpen: true };
		}
		case 'CLOSE_MENU_DRAWER': {
			return { ...state, menuDrawerOpen: false };
		}
	}
}

function HabitOptionsProvider({ children }: HabitOptionsProviderProps) {
	const pc = useMediaQuery(theme.breakpoints.up('lg'));

	const initialState: State = { menuDrawerOpen: pc, selectedStage: Stages.TRACKING };

	const [state, dispatch] = React.useReducer(optionsReducer, initialState);
	const value = { state, dispatch };
	return (
		<HabitOptionsContext.Provider value={value}>{children}</HabitOptionsContext.Provider>
	);
}

function useHabitOptions() {
	const context = React.useContext(HabitOptionsContext);
	if (context === undefined) {
		throw new Error('useCount must be used within a CountProvider');
	}
	return context;
}

export { HabitOptionsProvider, useHabitOptions };
