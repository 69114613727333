import { initializeApp } from 'firebase/app';
import {
	query,
	where,
	getDocs,
	getFirestore,
	doc,
	setDoc,
	deleteDoc,
	updateDoc,
	Timestamp,
	getDoc,
} from 'firebase/firestore';
import { collection } from 'firebase/firestore';
import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
	sendEmailVerification,
} from 'firebase/auth';
import { firebaseErrorCodeMessage } from './utils/firebaseErrorCodeMessage';
import { HabitData, Stages, UserData } from './types';

const firebaseConfig = {
	apiKey: 'AIzaSyA5SFI7Cup888GUvHBtX4-zN6BdJxcntsI',
	authDomain: 'poly-34a1a.firebaseapp.com',
	databaseURL: 'https://poly-34a1a-default-rtdb.firebaseio.com',
	projectId: 'poly-34a1a',
	storageBucket: 'poly-34a1a.appspot.com',
	messagingSenderId: '1002489714005',
	appId: '1:1002489714005:web:129f1c6651ca336b450bdc',
	measurementId: 'G-NEM1214VNW',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const timestamp = Timestamp.fromDate(new Date());

export const signInWithGoogle = async () => {
	try {
		const res = await signInWithPopup(auth, googleProvider);
		const user = res.user;

		const docRef = doc(collection(db, 'users'), user.uid);

		if (!user.email) return ['Email does not exist'];

		const userDoc = await getDoc(docRef);
		if (!userDoc.data()) {
			const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
			const newUser: Omit<UserData, 'id'> = {
				authProvider: 'google',
				name: user.displayName ? user.displayName : user.email,
				phoneNumber: user.phoneNumber || '',
				email: user.email,
				timeZone,
				lastEditedAt: timestamp,
				createdAt: timestamp,
			};

			await setDoc(docRef, newUser);
		}
	} catch (err: any) {
		return firebaseErrorCodeMessage(err);
	}
};

export const loginWithEmailAndPassword = async (email: string, password: string) => {
	try {
		const response = await signInWithEmailAndPassword(auth, email, password);
		if (!response.user.emailVerified) logout();
	} catch (err: any) {
		return firebaseErrorCodeMessage(err);
	}
};

export const registerWithEmailAndPassword = async (email: string, password: string) => {
	try {
		const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
		const { user } = await createUserWithEmailAndPassword(auth, email, password);

		const newUser: Omit<UserData, 'id'> = {
			authProvider: 'local',
			email,
			lastEditedAt: timestamp,
			createdAt: timestamp,
			timeZone,
			name: '',
			phoneNumber: '',
		};

		const docRef = doc(collection(db, 'users'), user.uid);
		await setDoc(docRef, newUser);

		const actionCodeSettings = { url: `https://polymastro.com/?email=${user.email}` };
		sendEmailVerification(user, actionCodeSettings);
		logout();
	} catch (err: any) {
		return firebaseErrorCodeMessage(err);
	}
};

export const sendPasswordReset = async (email: string) => {
	try {
		await sendPasswordResetEmail(auth, email);
		return ['Password Reset Email Sent!'];
	} catch (err: any) {
		return firebaseErrorCodeMessage(err);
	}
};

export const getHabitData = async () => {
	try {
		const uid = auth.currentUser?.uid;
		if (!uid) return;
		const q = query(collection(db, 'habits'), where('user', '==', uid));
		const receivedData = await getDocs(q);
		const data = receivedData.docs?.map(item => {
			return { id: item.id, ...item.data() };
		});
		return data;
	} catch (err) {
		return firebaseErrorCodeMessage(err);
	}
};

export const createHabit = async (
	title: string,
	goal: string,
	partialSuccess: string,
	stage: Stages
) => {
	try {
		const uid = auth.currentUser?.uid;
		if (!uid) return ['Undefined user'];
		const docRef = doc(collection(db, 'habits'));
		const newHabit: Omit<HabitData, 'id'> = {
			title,
			goal,
			partialSuccess,
			user: uid,
			createdAt: timestamp,
			lastEditedAt: timestamp,
			stats: [],
			stage,
		};
		await setDoc(docRef, newHabit);
		return { ...newHabit, id: docRef.id };
	} catch (err) {
		return firebaseErrorCodeMessage(err);
	}
};

export const editHabit = async (data: Partial<HabitData> & { id: string }) => {
	try {
		const { id, title, stats, stage, goal, partialSuccess } = data;
		const uid = auth.currentUser?.uid;
		if (!uid) return ['Undefined user'];

		const docRef = doc(collection(db, 'habits'), id);

		let editValues = {};
		if (title) editValues = { title, ...editValues };
		if (Array.isArray(stats) && stats.length > 0) editValues = { stats, ...editValues };
		if (stage) editValues = { stage, ...editValues };
		if (goal) editValues = { goal, ...editValues };
		if (partialSuccess) editValues = { partialSuccess, ...editValues };

		await updateDoc(docRef, {
			lastEditedAt: timestamp,
			...editValues,
		});
	} catch (err) {
		return firebaseErrorCodeMessage(err);
	}
};

export const deleteHabit = async (id: string) => {
	try {
		const uid = auth.currentUser?.uid;
		if (!uid) return ['Undefined user'];
		const docRef = doc(collection(db, 'habits'), id);
		await deleteDoc(docRef);
		return ['Successfully deleted habit'];
	} catch (err) {
		return firebaseErrorCodeMessage(err);
	}
};

export const logout = () => {
	signOut(auth);
};
