import {
	AppBar,
	Button,
	Container,
	ContainerProps,
	Grid,
	Toolbar,
	Typography,
	Box,
} from '@mui/material';
import theme from '../theme';

const containerStyling = {
	width: '100%',
	boxSizing: 'border-box',
	height: '100vh',
	display: 'flex',
	margin: 0,
};

const GuestContainer: React.FC<ContainerProps> = ({ children, ...other }) => {
	return (
		<Container sx={containerStyling} disableGutters maxWidth='xl' {...other}>
			<AppBar
				elevation={0}
				position='fixed'
				sx={{
					background: theme.palette.background.default,
					minHeight: 45,
					maxHeight: 45,
				}}>
				<Toolbar
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}>
					<Button href='/login' disableRipple color='primary'>
						<Typography
							variant='h6'
							noWrap
							sx={{ fontWeight: '800', color: 'text.primary' }}>
							Polymastro
						</Typography>
					</Button>
					<Box>
						<Button
							href='/login'
							sx={{
								margin: theme.spacing(1, 1),
								fontWeight: 'bold',
								color: 'text.primary',
							}}>
							Log In
						</Button>
						<Button
							href='/register'
							variant='contained'
							color='secondary'
							disableElevation
							sx={{ margin: theme.spacing(1), fontWeight: 'bold' }}>
							Try Free!
						</Button>
					</Box>
				</Toolbar>
			</AppBar>
			<Grid container alignItems='center' justifyContent={'center'}>
				{children}
			</Grid>
		</Container>
	);
};

export default GuestContainer;
