import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
	IconButton,
	useMediaQuery,
	Container,
	ContainerProps,
	AppBar,
	Toolbar,
	Typography,
} from '@mui/material';
import theme from '../theme';
import { logout } from '../firebase.config';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { useNavigate } from 'react-router-dom';
import { HabitData } from '../types';
import { useHabitOptions } from '../habitOptionsContext';

export const menuDrawerWidth = 220;
export const appBarHeight = 45;

export interface HabitItemProps extends ContainerProps {
	habits: HabitData[] | [];
	setHabits: React.Dispatch<React.SetStateAction<HabitData[]>>;
}

const UserContainer: React.FC<HabitItemProps> = ({
	habits,
	setHabits,
	children,
	...other
}): JSX.Element => {
	const navigate = useNavigate();
	const {
		state: { menuDrawerOpen },
		dispatch,
	} = useHabitOptions();

	const pc = useMediaQuery(theme.breakpoints.up('lg'));
	const tablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
	const phone = useMediaQuery(theme.breakpoints.down('sm'));

	const handleMenuDrawerOpen = () => dispatch({ type: 'OPEN_MENU_DRAWER' });
	const handleMenuDrawerClose = () => dispatch({ type: 'CLOSE_MENU_DRAWER' });

	const logoutUser = () => {
		navigate('/login');
		logout();
	};

	const appBarStyling = {
		background: theme.palette.background.default,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		// Transition the app bar to the right when the menu is opened on PC - when persistent
		...(menuDrawerOpen &&
			pc && {
				width: `calc(100% - ${menuDrawerWidth}px)`,
				marginLeft: `${menuDrawerWidth}px`,
				transition: theme.transitions.create(['margin', 'width'], {
					easing: theme.transitions.easing.easeOut,
					duration: theme.transitions.duration.enteringScreen,
				}),
			}),
		minHeight: appBarHeight,
		maxHeight: appBarHeight,
	};

	const containerStyling = {
		width: '100%',
		boxSizing: 'border-box',
		height: '100vh',
		display: 'flex',
		margin: 0,
	};

	return (
		<Container {...other} sx={containerStyling} disableGutters maxWidth='xl'>
			<AppBar position='fixed' elevation={0} sx={appBarStyling}>
				<Toolbar
					variant='dense'
					sx={{ minHeight: appBarHeight, maxHeight: appBarHeight }}>
					<IconButton
						aria-label='open drawer'
						onClick={handleMenuDrawerOpen}
						edge='start'
						sx={{ mr: 2, ...(menuDrawerOpen && { display: 'none' }) }}>
						<MenuIcon />
					</IconButton>
					<Typography
						sx={{ color: theme.palette.text.primary }}
						variant='body2'
						noWrap
						component='div'>
						Habits
					</Typography>
				</Toolbar>
				{phone ? <Divider /> : undefined}
			</AppBar>
			<Drawer
				variant={tablet || phone ? 'temporary' : 'persistent'}
				open={menuDrawerOpen}
				onClose={handleMenuDrawerClose}
				sx={{
					width: menuDrawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: menuDrawerWidth,
						boxSizing: 'border-box',
					},
				}}
				anchor='left'
				ModalProps={{ keepMounted: true }}>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						height: appBarHeight,
					}}>
					<IconButton
						onClick={handleMenuDrawerClose}
						aria-label='close navigation drawer'>
						<ChevronLeftIcon fontSize='medium' />
					</IconButton>
				</Box>
				<List>
					<ListItem disablePadding>
						<ListItemButton>
							<ListItemIcon>
								<AccountCircleIcon fontSize='small' />
							</ListItemIcon>
							User
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton onClick={logoutUser}>
							<ListItemIcon>
								<LogoutIcon fontSize='small' />
							</ListItemIcon>
							Logout
						</ListItemButton>
					</ListItem>
				</List>
				<Divider />
				<List>
					<ListItem disablePadding>
						<ListItemButton>
							<ListItemIcon>
								<CheckBoxIcon fontSize='small' />
							</ListItemIcon>
							Habits
						</ListItemButton>
					</ListItem>
				</List>
			</Drawer>
			{children}
		</Container>
	);
};

export default UserContainer;
