export const firebaseErrorCodeMessage = (err: any): string[] => {
	let errArr: string[] = [];
	const { code } = err;
	// Sign In Process Errors
	if (code.includes('weak-password'))
		errArr.push('Password should be 8+ characters long');
	if (code.includes('wrong-password')) errArr.push('Invalid password');
	if (code.includes('invalid-email')) errArr.push('Invalid email');
	if (code.includes('email-already-in-use')) errArr.push('Email already in use');
	if (code.includes('user-not-found')) errArr.push('User not found');
	if (code.includes('weak-password')) errArr.push('This password is too weak');
	if (code.includes('unverified-email'))
		errArr.push('Verify your email before logging in');
	// User caused errors
	if (code.includes('too-many-requests'))
		errArr.push('You made too many attempts, try later or message us');
	if (code.includes('account-exists-with-different-credential'))
		errArr.push('Your account needs confirmation');
	if (code.includes('credential-already-in-use'))
		errArr.push('Credential already in use');
	if (code.includes('app-not-authorized'))
		errArr.push('App not authorized to access this resource');
	if (code.includes('code-expired')) errArr.push('Your code expired, create a new one');
	// Other Errors
	if (code.includes('custom-token-mismatch')) errArr.push('Credential do not match');
	if (code.includes('cors-unsupported')) errArr.push('CORS is unsupported');
	if (code.includes('invalid-api-key')) errArr.push('Invalid API key');
	if (code.includes('invalid-user-token')) errArr.push('Invalid authentication');
	if (code.includes('unauthorized-domain')) errArr.push('Invalid origin');
	if (code.includes('null-user')) errArr.push('Null User');
	if (code.includes('operation-not-allowed')) errArr.push('Operation not allowed');
	if (code.includes('quota-exceeded')) errArr.push('Operation quota exceeded');
	if (code.includes('network-request-failed'))
		errArr.push('Network request failed, try again later');
	// Successful Action Notifications
	if (code.includes('user-signed-out')) errArr.push('User Signed Out');
	// Blanket Errors
	if (code.includes('invalid-argument'))
		errArr.push(
			'Our database received an invalid argument; if this continues, please message us'
		);
	if (code.includes('internal-error') || (err && errArr.length === 0))
		errArr.push(
			'We encountered an unexpected error; if this continues, please message us'
		);
	return errArr;
};
