import {
	Typography,
	Grid,
	TextField,
	Button,
	Box,
	Divider,
	LinearProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { InlineErrorMessage } from '../components/InlineErrorMessage';
import { auth, registerWithEmailAndPassword, signInWithGoogle } from '../firebase.config';
import { emailValid, passwordValid } from '../utils/validation';
import GuestContainer from '../components/GuestContainer';
import theme from '../theme';
import googleIcon from '../assets/images/google-icon.png';
import { useSnackbar } from 'notistack';
import backendMessageHandler from '../utils/backendMessageHandler';

const Register: React.FC = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');

	const [passwordError, setPasswordError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [user, authLoading] = useAuthState(auth);

	const navigate = useNavigate();

	useEffect(() => {
		if (user && user.emailVerified) navigate('/habits/');
		if (user && !user.emailVerified) navigate('/verify-email');
	}, [user, navigate]);

	const submitRegisterRequest = async () => {
		let error = { emailErr: false, passwordErr: false };
		if (!emailValid(email)) {
			error.emailErr = true;
			setEmailError(error.emailErr);
		} else setEmailError(false);

		if (!passwordValid(password)) {
			error.passwordErr = true;
			setPasswordError(error.passwordErr);
		} else setPasswordError(false);

		if (Object.values(error).some(err => err === true)) return;
		setLoading(true);
		const backendResponse = await registerWithEmailAndPassword(email, password);
		if (backendResponse) backendMessageHandler(backendResponse, enqueueSnackbar);
		setLoading(false);
	};

	const submitRegisterRequestWithGoogle = async () => {
		setLoading(true);
		const backendResponse = await signInWithGoogle();
		setLoading(false);
		if (backendResponse) backendMessageHandler(backendResponse, enqueueSnackbar);
	};

	return loading || authLoading ? (
		<LinearProgress />
	) : (
		<GuestContainer>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: { xs: 320, sm: 350, md: 350, lg: 350 },
				}}
				component='form'
				noValidate>
				<Typography variant='h4' fontWeight={'bold'} sx={{ margin: 'auto' }}>
					New Account
				</Typography>
				<Button
					variant='outlined'
					fullWidth
					disableElevation
					sx={{
						borderRadius: 10,
						margin: theme.spacing(3, 0, 0, 0),
						border: `1px solid #ccc`,
					}}
					onClick={submitRegisterRequestWithGoogle}>
					<Box
						component='img'
						sx={{
							height: 12,
							width: 'auto',
							opacity: 1,
							marginRight: 1,
						}}
						src={googleIcon}
					/>
					Connect with Google
				</Button>
				<Divider flexItem sx={{ margin: theme.spacing(3, 2, 1, 2) }} variant='middle' />
				<TextField
					value={email}
					onChange={e => setEmail(e.target.value)}
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='email'
					label='Email Address'
					name='email'
					autoComplete='email'
					autoFocus
					error={emailError}
				/>
				<InlineErrorMessage errorTriggered={emailError} message='Email not valid' />
				<TextField
					value={password}
					onChange={e => setPassword(e.target.value)}
					variant='outlined'
					margin='normal'
					required
					fullWidth
					name='password'
					label='Password'
					type='password'
					id='password'
					autoComplete='current-password'
					error={passwordError}
				/>
				<InlineErrorMessage
					errorTriggered={passwordError}
					message='Password should be 8+ characters long'
				/>
				<Button
					disableElevation
					variant='contained'
					fullWidth
					color='secondary'
					onClick={submitRegisterRequest}
					sx={{
						margin: theme.spacing(1, 0, 1),
					}}>
					Continue With Email
				</Button>
				<Grid container justifyContent='flex-end'>
					<Grid item>
						<Button href='/login' sx={{ padding: theme.spacing(1, 0.5) }}>
							<Typography variant='body2'>Already have an account? Login</Typography>
						</Button>
					</Grid>
				</Grid>
			</Box>
		</GuestContainer>
	);
};

export default Register;
