import {
	Typography,
	TextField,
	Button,
	Grid,
	Box,
	Divider,
	LinearProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { InlineErrorMessage } from '../components/InlineErrorMessage';
import theme from '../theme';
import { emailValid, passwordValid } from '../utils/validation';
import { useNavigate } from 'react-router-dom';
import {
	auth,
	loginWithEmailAndPassword,
	logout,
	signInWithGoogle,
} from '../firebase.config';
import { useAuthState } from 'react-firebase-hooks/auth';
import googleIcon from '../assets/images/google-icon.png';
import GuestContainer from '../components/GuestContainer';
import { useSnackbar } from 'notistack';
import backendMessageHandler from '../utils/backendMessageHandler';
const SignIn: React.FC = () => {
	const [passwordError, setPasswordError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [user, authLoading] = useAuthState(auth);

	const navigate = useNavigate();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const emailParameter = urlParams.get('email');
		// If you came from email verification link
		if (emailParameter) {
			if (user) logout();
			setEmail(emailParameter);
			navigate('/login');
		} else {
			if (user && user.emailVerified) navigate('/habits/');
			if (user && !user.emailVerified) navigate('/verify-email');
		}
	}, [user, navigate]);

	const submitLoginRequest = async () => {
		let err = { emailErr: false, passwordErr: false };
		if (!emailValid(email)) {
			err.emailErr = true;
			setEmailError(err.emailErr);
		} else setEmailError(false);
		if (!passwordValid(password)) {
			err.passwordErr = true;
			setPasswordError(err.passwordErr);
		} else setPasswordError(false);

		if (Object.values(err).some(err => err === true)) return;
		setLoading(true);
		const backendResponse = await loginWithEmailAndPassword(email, password);
		if (backendResponse) backendMessageHandler(backendResponse, enqueueSnackbar);
		setLoading(false);
	};

	const submitLoginRequestWithGoogle = async () => {
		setLoading(true);
		const backendResponse = await signInWithGoogle();
		setLoading(false);
		if (backendResponse) backendMessageHandler(backendResponse, enqueueSnackbar);
	};

	return loading || authLoading ? (
		<LinearProgress />
	) : (
		<GuestContainer>
			<Box
				component='form'
				noValidate
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: { xs: 320, sm: 350, md: 350, lg: 350 },
				}}>
				<Typography variant='h4' fontWeight={'bold'} sx={{ margin: 'auto' }}>
					Log In
				</Typography>
				<Button
					variant='outlined'
					fullWidth
					disableElevation
					sx={{
						borderRadius: 10,
						margin: theme.spacing(3, 0, 0, 0),
						border: `1px solid #ccc`,
					}}
					onClick={submitLoginRequestWithGoogle}>
					<Box
						component='img'
						sx={{
							height: 12,
							width: 'auto',
							opacity: 1,
							marginRight: 1,
						}}
						src={googleIcon}
					/>
					Continue with Google
				</Button>
				<Divider flexItem sx={{ margin: theme.spacing(3, 2, 1, 2) }} variant='middle' />
				<TextField
					value={email}
					onChange={e => setEmail(e.target.value)}
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='email'
					label='Email Address'
					name='email'
					autoComplete='email'
					autoFocus
					error={emailError}
				/>
				<InlineErrorMessage errorTriggered={emailError} message='Email not valid' />
				<TextField
					value={password}
					onChange={e => setPassword(e.target.value)}
					variant='outlined'
					margin='normal'
					required
					fullWidth
					name='password'
					label='Password'
					type='password'
					id='password'
					autoComplete='current-password'
					error={passwordError}
				/>
				<InlineErrorMessage
					errorTriggered={passwordError}
					message='Password should be 8+ characters long'
				/>
				<Button
					disableElevation
					variant='contained'
					fullWidth
					color='secondary'
					onClick={submitLoginRequest}
					sx={{
						margin: theme.spacing(1, 0, 1),
					}}>
					Continue With Email
				</Button>
				<Grid container justifyContent='space-between' fontStyle={'#fff'}>
					<Grid item>
						<Button href='/forgot-password' sx={{ padding: theme.spacing(1, 0.5) }}>
							<Typography variant='body2'>Forgot password?</Typography>
						</Button>
					</Grid>
					<Grid item>
						<Button href='/register' sx={{ padding: theme.spacing(1, 0.5) }}>
							<Typography variant='body2'>No account? Register</Typography>
						</Button>
					</Grid>
				</Grid>
			</Box>
		</GuestContainer>
	);
};

export default SignIn;
