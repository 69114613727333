import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { auth } from '../firebase.config';
import NoMatch from '../pages/NoMatch';

export default function ProtectedRoute() {
	let location = useLocation();
	const [user, authLoading] = useAuthState(auth);

	if (authLoading) return <LinearProgress />;
	if (user) return <Outlet />;
	if (!user && !authLoading) return <Navigate to='/login' state={{ from: location }} />;
	else return <NoMatch />;
}
