import { useHabitOptions } from '../habitOptionsContext';
import { HabitData, Stages } from '../types';
import AddTaskIcon from '@mui/icons-material/AddTask';
import WorkIcon from '@mui/icons-material/Work';
import EventNoteIcon from '@mui/icons-material/EventNote';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import theme from '../theme';
import { MenuItem, Select, SelectChangeEvent, useMediaQuery } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { materialUIDefaultMenuStyle } from './StyledMenu';
export interface HabitStageSelectorProps {
	habits: HabitData[];
}

const HabitStageSelector: React.FC<HabitStageSelectorProps> = ({
	habits,
}): JSX.Element => {
	const {
		state: { selectedStage },
		dispatch,
	} = useHabitOptions();
	const navigate = useNavigate();

	const pc = useMediaQuery(theme.breakpoints.up('lg'));
	const tablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

	const handleStageChange = (event: SelectChangeEvent<Stages>) => {
		if (selectedStage === event.target.value) return;

		const documentsFilteredByStage: HabitData[] | undefined = [
			...habits.filter(habit => habit.stage === (event.target.value as Stages)),
		];

		dispatch({ payload: event.target.value as Stages, type: 'SELECT_STAGE' });
		if (tablet || pc) {
			// Select a habit after switching stage on tablet or pc, if one exists. Default function.
			if (documentsFilteredByStage.length > 0) {
				const firstDocumentId = documentsFilteredByStage[0].id;
				if (firstDocumentId) navigate(`/habits/${firstDocumentId}`, { replace: true });
			} else if (documentsFilteredByStage.length === 0)
				navigate(`/habits/`, { replace: true });
		}
	};

	return (
		<Select
			name='stage'
			variant='filled'
			defaultValue={selectedStage}
			IconComponent={KeyboardArrowDownIcon}
			onChange={handleStageChange}
			MenuProps={{ sx: { ...materialUIDefaultMenuStyle } }}
			sx={{
				'& .MuiSelect-select': {
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: '6px 0px 5px 9px',
					// Styling for the stage icon
					'& .MuiSvgIcon-root': {
						fontSize: 17,
						color: theme.palette.text.secondary,
						width: '1em',
						height: '1em',
					},
				},
				'& #select-display': {
					paddingRight: '2rem',
				},
				// Styling for the arrow down icon
				'& .MuiSvgIcon-root': {
					fontSize: 20,
					color: theme.palette.text.secondary,
				},
			}}
			SelectDisplayProps={{
				id: 'select-display',
				style: {
					display: 'flex',
					justifyContent: 'flex-start',
					gap: 5,
					color:
						theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
				},
			}}
			inputProps={{ id: 'input-attribute' }}>
			<MenuItem selected={selectedStage === Stages.UPCOMING} value={'UPCOMING'}>
				<AddTaskIcon fontSize='small' />
				Upcoming
			</MenuItem>
			<MenuItem selected={selectedStage === Stages.TRACKING} value={'TRACKING'}>
				<WorkIcon fontSize='small' />
				Tracking
			</MenuItem>
			<MenuItem selected={selectedStage === Stages.COMPLETED} value={'COMPLETED'}>
				<EventNoteIcon fontSize='small' />
				Completed
			</MenuItem>
		</Select>
	);
};

export default HabitStageSelector;
