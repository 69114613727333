import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import theme from './theme';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { HabitOptionsProvider } from './habitOptionsContext';
import CssBaseline from '@mui/material/CssBaseline';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<React.StrictMode>
			<SnackbarProvider maxSnack={3} preventDuplicate>
				<HabitOptionsProvider>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<App />
					</ThemeProvider>
				</HabitOptionsProvider>
			</SnackbarProvider>
		</React.StrictMode>
	</React.StrictMode>
);
