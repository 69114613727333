import Habits from './pages/Habits';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { ReactElement, Suspense } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import ProtectedRoute from './components/ProtectedRoute';
import { useSnackbar } from 'notistack';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import NoMatch from './pages/NoMatch';
import Register from './pages/Register';
import VerifyEmail from './pages/VerifyEmail';

const App: React.FC = (): ReactElement => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const offlineKey = 'offline-snackbar-key';
	window.addEventListener('offline', () => {
		enqueueSnackbar("It Looks Like You're Offline", { persist: true, key: offlineKey });
	});
	window.addEventListener('online', () => {
		closeSnackbar(offlineKey);
	});

	return (
		<BrowserRouter>
			<Suspense fallback={<LinearProgress />}>
				<Routes>
					<>
						<Route path='/' element={<Login />} />
						<Route path='/login' element={<Login />} />
						<Route path='/register' element={<Register />} />
						<Route path='/forgot-password' element={<ForgotPassword />} />
						<Route path='/verify-email' element={<VerifyEmail />} />
						<Route element={<ProtectedRoute />}>
							<Route path='/habits/:id' element={<Habits />} />
							<Route path='/habits/' element={<Habits />} />
						</Route>
					</>
					<Route path='*' element={<NoMatch />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};

export default App;
