import { Menu, MenuProps } from '@mui/material';
import { SxProps, Theme, alpha } from '@mui/material/styles';
import theme from '../theme';

// Used https://mui.com/material-ui/react-menu/#customization

export const materialUIDefaultMenuStyle: SxProps<Theme> = {
	'& .MuiPaper-root': {
		marginTop: theme.spacing(1),
		minWidth: 120,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
};

const StyledMenu = (props: MenuProps) => {
	return (
		<Menu
			elevation={3}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			disableScrollLock={true}
			sx={materialUIDefaultMenuStyle}
			{...props}
		/>
	);
};

export default StyledMenu;
