import {
	createTheme,
	SimplePaletteColorOptions,
	responsiveFontSizes,
} from '@mui/material';
import { StatTags } from './types';

declare module '@mui/material/styles' {
	interface Palette {
		variable: SimplePaletteColorOptions;
		complete: SimplePaletteColorOptions;
		incomplete: SimplePaletteColorOptions;
		notToday: SimplePaletteColorOptions;
		partial: SimplePaletteColorOptions;
		empty: SimplePaletteColorOptions;
	}
	interface PaletteOptions {
		variable: SimplePaletteColorOptions;
		complete: SimplePaletteColorOptions;
		incomplete: SimplePaletteColorOptions;
		notToday: SimplePaletteColorOptions;
		partial: SimplePaletteColorOptions;
		empty: SimplePaletteColorOptions;
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		variable: true;
		complete: true;
		incomplete: true;
		notToday: true;
		partial: true;
		empty: true;
	}
}
const { palette } = createTheme();

const theme = createTheme({
	palette: {
		// mode: 'dark',
		secondary: palette.augmentColor({
			color: { light: '#ff867c', main: '#e16259' },
		}),
		variable: palette.augmentColor({
			color: { light: '#63ccff', main: '#1976d2', dark: '#006db3' },
		}),
		complete: palette.augmentColor({
			color: { main: '#43a047' },
		}), // green 7
		incomplete: palette.augmentColor({
			color: { main: '#ff7043' },
		}), // deep orange 5
		notToday: palette.augmentColor({
			color: { main: '#628393' },
		}), //grey 7 #
		partial: palette.augmentColor({
			color: { main: '#ffb74d' },
		}),
		empty: palette.augmentColor({
			color: { light: '#00000000', main: '#859BA663' },
		}),
		action: {
			selected: 'rgba(45, 170, 219, 0.24)',
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: themeParam => ({
				body:
					themeParam.palette.mode === 'dark'
						? scrollbarOptions({
								track: 'rgba(202, 204, 206, 0.04)',
								thumb: '#474c50',
								active: '#555',
						  })
						: scrollbarOptions({ track: '#edece9', thumb: '#D3D1CB', active: '#959595' }),
			}),
		},
	},
	breakpoints: { values: { xl: 1920, lg: 1100, md: 900, sm: 680, xs: 340 } },
	typography: {
		fontFamily: [
			'Nunito Sans',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		button: {
			textTransform: 'none',
		},
	},
});

export default responsiveFontSizes(theme);

function scrollbarOptions(options: { track: string; thumb: string; active: string }) {
	return {
		scrollbarColor: `${options.thumb} ${options.track}`,
		'&::-webkit-scrollbar, & *::-webkit-scrollbar': {
			backgroundColor: options.track,
			width: '10px',
			height: '10px',
		},
		'&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
			backgroundColor: options.thumb,
			minHeight: 24,
		},
		'&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
			backgroundColor: options.active,
		},
		'&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
			backgroundColor: options.active,
		},
		'&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
			backgroundColor: options.active,
		},
		'&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
			backgroundColor: options.track,
		},
	};
}

export const tagColorSelector = (
	tag: StatTags | 'OUTSIDE'
	// type: 'CALENDAR' | 'BUTTON'
) => {
	return {
		// ...(tag === StatTags.EMPTY && {
		backgroundColor: 'inherit',
		boxShadow: 'none',
		border: `1px solid #aaa`,
		color: theme.palette.text.primary,
		'&:hover, &:focus': {
			backgroundColor: theme.palette.empty.main,
		},
		// }),
		...(tag === StatTags.COMPLETED && {
			backgroundColor: theme.palette.complete.light,
			'&:hover, &:focus': {
				backgroundColor: theme.palette.complete.main,
			},
		}),
		...(tag === StatTags.INCOMPLETED && {
			backgroundColor: theme.palette.incomplete.light,
			'&:hover, &:focus': {
				backgroundColor: theme.palette.incomplete.main,
			},
		}),
		...(tag === StatTags.NOT_TODAY && {
			backgroundColor: theme.palette.notToday.light,
			'&:hover, &:focus': {
				backgroundColor: theme.palette.notToday.main,
			},
		}),
		...(tag === StatTags.PARTIAL && {
			backgroundColor: theme.palette.partial.light,
			'&:hover, &:focus': {
				backgroundColor: theme.palette.partial.main,
			},
		}),
		...(tag === 'OUTSIDE' && {
			border: 'none',
			'&:hover, &:focus': {
				backgroundColor: theme.palette.empty.main,
			},
		}),
		// ...((tag === 'OUTSIDE' && type === "CALENDAR") && {
		// 	backgroundColor: 'inherit',
		// 	'&:hover, &:focus': {
		// 		backgroundColor: theme.palette.empty.main,
		// 	},
		// }),
	};
};
