import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import GuestContainer from '../components/GuestContainer';

const NoMatch: React.FC = (): ReactElement => {
	return (
		<GuestContainer>
			<header className='App-header'>
				<p>Uh Oh! I can't find the resource you're searching for</p>
				<Link to={'/login'}>Return to the safe abode!</Link>
			</header>
		</GuestContainer>
	);
};

export default NoMatch;
