import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { HabitData, Stages } from '../types';
import { InlineErrorMessage } from './InlineErrorMessage';
import { createHabit } from '../firebase.config';
import { useSnackbar } from 'notistack';
import backendMessageHandler from '../utils/backendMessageHandler';
import recentMissingDataExists from '../utils/recentMissingDataExists';

interface DialogProps {
	open: boolean;
	habits: HabitData[];
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setHabits: React.Dispatch<React.SetStateAction<HabitData[]>>;
}
export default function CreateHabitDialog({
	open,
	habits,
	setOpen,
	setHabits,
}: DialogProps) {
	const { enqueueSnackbar } = useSnackbar();

	const [title, setTitle] = useState('');
	const [goalDescription, setGoalDescription] = useState('');
	const [partialSuccessDescription, setPartialSuccessDescription] = useState('');
	const [titleError, setTitleError] = useState(false);

	const handleClose = () => {
		setTitle('');
		setPartialSuccessDescription('');
		setGoalDescription('');
		setOpen(false);
		setTitleError(false);
	};

	const changeTitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setTitle(e.target.value);
	};
	const changeGoalDescription = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setGoalDescription(e.target.value);
	};
	const changePartialSuccessDescription = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setPartialSuccessDescription(e.target.value);
	};

	const addHabit = async () => {
		if (title.length === 0 || typeof title !== 'string') return setTitleError(true);
		const stage = Stages.TRACKING;
		const backendResponse = await createHabit(
			title,
			goalDescription,
			partialSuccessDescription,
			stage
		);
		let documentData = backendMessageHandler(backendResponse, enqueueSnackbar);
		if (!documentData || Array.isArray(documentData)) return handleClose();
		documentData = recentMissingDataExists([documentData]);
		setHabits([...habits, ...documentData]);
		handleClose();
		return;
	};

	return (
		<>
			<Dialog fullWidth open={open} maxWidth={'sm'} onClose={handleClose}>
				<DialogContent sx={{ padding: '1.5rem 1.5rem 0 1.5rem' }}>
					<TextField
						multiline
						autoFocus
						margin='dense'
						id='name'
						label='Add habit title'
						type='name'
						fullWidth
						variant='standard'
						value={title}
						onChange={changeTitle}
						error={titleError}
						InputProps={{ sx: { fontSize: '1.2rem', fontWeight: '600' } }}
					/>
					<InlineErrorMessage errorTriggered={titleError} message='Add a title' />
				</DialogContent>
				<DialogContent sx={{ padding: '.2rem 1.5rem' }}>
					<TextField
						multiline
						minRows={2}
						margin='dense'
						id='name'
						label='What is the aim or objective of this habit?'
						type='text'
						fullWidth
						variant='filled'
						value={goalDescription}
						onChange={changeGoalDescription}
					/>
				</DialogContent>
				<DialogContent sx={{ padding: '.2rem 1.5rem' }}>
					<TextField
						multiline
						minRows={2}
						margin='dense'
						id='name'
						label={`What would partial success look like for this habit?`}
						type='text'
						fullWidth
						variant='filled'
						value={partialSuccessDescription}
						onChange={changePartialSuccessDescription}
					/>
				</DialogContent>
				<DialogActions>
					<Button variant='outlined' onClick={addHabit}>
						Add Habit
					</Button>
					<Button onClick={handleClose}>Cancel</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
