import { Typography, TextField } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import React, { useState } from 'react';
import { InlineErrorMessage } from '../components/InlineErrorMessage';
import { emailValid } from '../utils/validation';
import { sendPasswordReset } from '../firebase.config';
import GuestContainer from '../components/GuestContainer';
import { useSnackbar } from 'notistack';
import theme from '../theme';

const ForgotPassword: React.FC = () => {
	const [emailError, setEmailError] = useState(false);
	const [email, setEmail] = useState('');
	const { enqueueSnackbar } = useSnackbar();
	const submitResetPasswordRequest = async () => {
		if (!emailValid(email)) return setEmailError(true);
		const backendResponse = await sendPasswordReset(email);
		backendResponse.forEach(message => enqueueSnackbar(message));
	};

	return (
		<GuestContainer>
			<Box
				maxWidth={'xs'}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'left',
					width: { xs: 320, sm: 350, md: 350, lg: 350 },
				}}
				component='form'
				noValidate>
				<Typography variant='h4' fontWeight={'bold'} sx={{ margin: 'auto' }}>
					Send Reset Email
				</Typography>
				<TextField
					value={email}
					onChange={e => setEmail(e.target.value)}
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='email'
					label='Email Address'
					name='email'
					autoComplete='email'
					autoFocus
					error={emailError}
				/>
				<InlineErrorMessage errorTriggered={emailError} message='Email not valid' />
				<ButtonGroup
					fullWidth
					variant='contained'
					aria-label='contained primary button group'
					disableElevation
					sx={{ margin: theme.spacing(1, 0) }}>
					<Button color='secondary' onClick={submitResetPasswordRequest}>
						Send
					</Button>
					<Button color='secondary' variant='outlined' href='/login'>
						Cancel
					</Button>
				</ButtonGroup>
			</Box>
		</GuestContainer>
	);
};

export default ForgotPassword;
