import { MenuItem } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { editHabit } from '../firebase.config';
import { HabitData, StatTags } from '../types';
import backendMessageHandler from '../utils/backendMessageHandler';
import StyledMenu from './StyledMenu';
import { useSnackbar } from 'notistack';
import CheckIcon from '@mui/icons-material/Check';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import dayjs, { Dayjs } from 'dayjs';
export interface HabitTagSelectorProps {
	habit: HabitData;
	habits: HabitData[];
	anchorEl: HTMLElement | null;
	selectedDate: Dayjs | null;
	setHabits: React.Dispatch<React.SetStateAction<HabitData[]>>;
	setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

const HabitTagSelector: React.FC<HabitTagSelectorProps> = ({
	habit,
	habits,
	anchorEl,
	selectedDate,
	setHabits,
	setAnchorEl,
}): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();

	if (!selectedDate) return <></>;

	const handleCheckerClose = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	const setTagForSelectedDay = async (
		event: React.MouseEvent<HTMLElement>,
		tag: StatTags
	) => {
		let modifiedStats = habit.stats;

		const selectedStatIndex = habit.stats.findIndex(stat =>
			dayjs(selectedDate).isSame(stat.forDate.seconds * 1000, 'day')
		);

		const newStat = {
			tag,
			forDate: Timestamp.fromDate(dayjs(selectedDate).toDate()),
			createdAt: Timestamp.fromDate(new Date()),
		};
		// if the selected tag doesn't exist yet, add a new stat object
		if (selectedStatIndex === -1) modifiedStats.unshift(newStat);
		else {
			if (modifiedStats[selectedStatIndex].tag === tag) return handleCheckerClose(event);
			else modifiedStats[selectedStatIndex].tag = tag;
		}

		let modifiedHabit = {
			todayTag: habit.todayTag,
			yesterdayTag: habit.yesterdayTag,
		};

		if (dayjs().isSame(selectedDate, 'day')) modifiedHabit.todayTag = tag;
		if (dayjs().add(-1, 'day').isSame(selectedDate, 'day'))
			modifiedHabit.yesterdayTag = tag;

		handleCheckerClose(event);
		// OPTIMIZE -- Measurably slows down application
		setHabits([
			...habits.map(mappedHabit =>
				mappedHabit.id === habit.id
					? { ...mappedHabit, stats: modifiedStats, ...modifiedHabit }
					: mappedHabit
			),
		]);
		const backendResponse = await editHabit({
			id: habit.id,
			stats: modifiedStats,
		});
		backendMessageHandler(backendResponse, enqueueSnackbar);
	};

	return (
		<StyledMenu
			id='habit-status-selection-menu'
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={handleCheckerClose}>
			<MenuItem onClick={e => setTagForSelectedDay(e, StatTags.COMPLETED)}>
				<DoneAllOutlinedIcon />
				Complete
			</MenuItem>
			<MenuItem onClick={e => setTagForSelectedDay(e, StatTags.PARTIAL)}>
				<CheckIcon />
				Partial
			</MenuItem>
			<MenuItem onClick={e => setTagForSelectedDay(e, StatTags.INCOMPLETED)}>
				<CloseOutlinedIcon />
				Incomplete
			</MenuItem>
			<MenuItem onClick={e => setTagForSelectedDay(e, StatTags.NOT_TODAY)}>
				<ArrowRightAltOutlinedIcon />
				Not Today
			</MenuItem>
		</StyledMenu>
	);
};

export default HabitTagSelector;
