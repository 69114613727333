import { Timestamp } from 'firebase/firestore';

export enum StatTags {
	COMPLETED = 'COMPLETED',
	INCOMPLETED = 'INCOMPLETED',
	NOT_TODAY = 'NOT_TODAY',
	PARTIAL = 'PARTIAL',
	EMPTY = 'EMPTY',
}

export enum Stages {
	UPCOMING = 'UPCOMING',
	TRACKING = 'TRACKING',
	COMPLETED = 'COMPLETED',
}
export enum Weekdays {
	Sun,
	Mon,
	Tue,
	Wed,
	Thu,
	Fri,
	Sat,
}

export interface HabitData {
	id: string;
	createdAt: Timestamp;
	lastEditedAt: Timestamp;
	user: string;
	title: string;
	goal: string;
	partialSuccess: string;
	todayTag?: StatTags;
	yesterdayTag?: StatTags;
	stage: Stages;
	stats: Array<{
		forDate: Timestamp;
		createdAt: Timestamp;
		tag: StatTags;
	}>;
}

export interface UserData {
	id: string;
	authProvider: 'google' | 'local';
	name: string;
	email: string;
	phoneNumber: string;
	timeZone: string;
	createdAt: Timestamp;
	lastEditedAt: Timestamp;
}
