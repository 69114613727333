import { ProviderContext } from 'notistack';
import { HabitData } from '../types';

const backendMessageHandler = (
	backendResponse: any,
	enqueueSnackbar: ProviderContext['enqueueSnackbar']
): undefined | HabitData | HabitData[] => {
	if (!backendResponse) return undefined;
	if (
		Array.isArray(backendResponse) &&
		backendResponse.every((item: any) => typeof item === 'string')
	) {
		backendResponse.forEach((message: any) => enqueueSnackbar(message as string));
		return undefined;
	}
	const userData = backendResponse;
	return userData as any;
};
export default backendMessageHandler;
