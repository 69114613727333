import { HabitData, Stages } from '../types';
import { MenuItem } from '@mui/material';
import { useState } from 'react';
import StyledMenu from './StyledMenu';
import EditIcon from '@mui/icons-material/Edit';
import QueueIcon from '@mui/icons-material/Queue';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import WorkIcon from '@mui/icons-material/Work';
import { useHabitOptions } from '../habitOptionsContext';
import { editHabit } from '../firebase.config';
import backendMessageHandler from '../utils/backendMessageHandler';
import { useSnackbar } from 'notistack';
import DeleteHabitDialog from './DeleteHabitDialog';
import TextChangePopover from './TextChangePopover';

export interface HabitSettingsMenuProps {
	habit: HabitData;
	habits: HabitData[];
	setHabits: React.Dispatch<React.SetStateAction<HabitData[]>>;
	contextMenu: {
		mouseX: number;
		mouseY: number;
	};
	setContextMenu: (event: React.MouseEvent | null) => void;
}

const HabitSettingsMenu: React.FC<HabitSettingsMenuProps> = ({
	habit,
	habits,
	setHabits,
	contextMenu,
	setContextMenu,
}): JSX.Element => {
	const {
		state: { selectedStage },
	} = useHabitOptions();
	const { enqueueSnackbar } = useSnackbar();

	const [popoverAnchorEl, setPopoverAnchorEl] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const handlePopoverAnchorEl = (event: React.MouseEvent | null) => {
		if (event === null) setPopoverAnchorEl(null);
		else {
			const newPopoverAnchorEl =
				popoverAnchorEl === null
					? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 }
					: null;
			setPopoverAnchorEl(newPopoverAnchorEl);
			event.stopPropagation();
			event.preventDefault();
		}
	};

	const closeMenu = () => {
		setContextMenu(null);
		handlePopoverAnchorEl(null);
	};

	const handleMoveToStage = async (stage: Stages) => {
		const modifiedHabits = habits.map(unchangedHabit =>
			unchangedHabit.id === habit.id ? { ...habit, stage } : unchangedHabit
		);

		setHabits(modifiedHabits);
		const backendResponse = await editHabit({ id: habit.id, stage });
		backendMessageHandler(backendResponse, enqueueSnackbar);
	};

	const handleDeleteDialogOpen = () => {
		setDeleteDialogOpen(true);
	};
	const handleDeleteDialogClosed = () => {
		setDeleteDialogOpen(false);
		setContextMenu(null);
	};

	const saveTitleChanges = async (newTitle: string) => {
		setContextMenu(null);
		if (newTitle !== habit.title) {
			const backendResponse = await editHabit({ id: habit.id, title: newTitle });
			backendMessageHandler(backendResponse, enqueueSnackbar);
			const modifiedHabits = habits.map(mappedHabit =>
				mappedHabit.id === habit.id ? { ...mappedHabit, title: newTitle } : mappedHabit
			);
			setHabits(modifiedHabits);
		}
	};

	const settingsMenuOpen = !deleteDialogOpen && !popoverAnchorEl;

	return (
		<StyledMenu
			id='habit-status-selection-menu'
			keepMounted
			anchorReference='anchorPosition'
			anchorPosition={
				settingsMenuOpen
					? { top: contextMenu.mouseY, left: contextMenu.mouseX }
					: undefined
			}
			autoFocus
			open={Boolean(settingsMenuOpen)}
			onClose={closeMenu}>
			<MenuItem id='edit habit' onClick={handlePopoverAnchorEl} disableRipple>
				<EditIcon fontSize='small' />
				Rename
			</MenuItem>
			<MenuItem id='delete habit' onClick={handleDeleteDialogOpen} disableRipple>
				<DeleteIcon fontSize='small' />
				Delete
			</MenuItem>
			{selectedStage !== Stages.UPCOMING && (
				<MenuItem
					id='move habit to upcoming'
					onClick={() => handleMoveToStage(Stages.UPCOMING)}
					disableRipple>
					<QueueIcon fontSize='small' />
					Move To Upcoming
				</MenuItem>
			)}
			{selectedStage !== Stages.TRACKING && (
				<MenuItem
					id='move habit to completed'
					onClick={() => handleMoveToStage(Stages.TRACKING)}
					disableRipple>
					<WorkIcon fontSize='small' />
					Move To Tracking
				</MenuItem>
			)}
			{selectedStage !== Stages.COMPLETED && (
				<MenuItem
					id='move habit to completed'
					onClick={() => handleMoveToStage(Stages.COMPLETED)}
					disableRipple>
					<CheckCircleOutlineIcon fontSize='small' />
					Move To Completed
				</MenuItem>
			)}
			<TextChangePopover
				onClose={closeMenu}
				handlePopoverAnchorEl={handlePopoverAnchorEl}
				initialValue={habit.title}
				popoverAnchorEl={popoverAnchorEl}
				saveChanges={saveTitleChanges}
				valueTitle='habit title'
			/>
			{deleteDialogOpen && (
				<DeleteHabitDialog
					habit={habit}
					habits={habits}
					setHabits={setHabits}
					closeDialog={handleDeleteDialogClosed}
				/>
			)}
		</StyledMenu>
	);
};

export default HabitSettingsMenu;
