import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { tagColorSelector } from '../theme';
import { Grid, Typography, Divider, IconButton, Box, alpha } from '@mui/material';
import { HabitData, StatTags } from '../types';
import HabitTagSelector from './HabitTagSelector';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import TextChangePopover from './TextChangePopover';
import { editHabit } from '../firebase.config';
import backendMessageHandler from '../utils/backendMessageHandler';
import { useSnackbar } from 'notistack';

export interface HabitCalendarProps {
	habits: HabitData[];
	setHabits: React.Dispatch<React.SetStateAction<HabitData[]>>;
}

const HabitCalendar: React.FC<HabitCalendarProps> = ({
	habits,
	setHabits,
}): JSX.Element => {
	const { id } = useParams();
	const { enqueueSnackbar } = useSnackbar();

	const [value, setValue] = React.useState<Dayjs | null>(dayjs());
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const currentHabit = habits.find(mappedHabit => mappedHabit.id === id);
	const [partialSuccessAnchorEl, setPartialSuccessAnchorEl] = React.useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [goalAnchorEl, setGoalAnchorEl] = React.useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	// If no current habit exists return nothing
	if (!currentHabit) return <></>;

	const prohibitedDates = (date: Dayjs) => {
		const beforeHabitCreated = dayjs(date).isBefore(
			dayjs(currentHabit.createdAt.seconds * 1000),
			'day'
		);

		const afterToday = dayjs(date).isAfter(dayjs().endOf('day'));
		return beforeHabitCreated || afterToday;
	};

	const handlePartialSuccessAnchorEl = (event: React.MouseEvent | null) => {
		if (event === null) setPartialSuccessAnchorEl(null);
		else {
			const newPartialSuccessAnchorEl =
				partialSuccessAnchorEl === null
					? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 }
					: null;
			setPartialSuccessAnchorEl(newPartialSuccessAnchorEl);
			event.stopPropagation();
			event.preventDefault();
		}
	};
	const handleGoalAnchorEl = (event: React.MouseEvent | null) => {
		if (event === null) setGoalAnchorEl(null);
		else {
			const newGoalAnchorEl =
				goalAnchorEl === null
					? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 }
					: null;
			setGoalAnchorEl(newGoalAnchorEl);
			event.stopPropagation();
			event.preventDefault();
		}
	};

	const handleCheckerMenuOpen = (
		event: React.MouseEvent<HTMLElement>,
		date: Dayjs | null
	) => {
		if (!date || (date && prohibitedDates(date))) {
			setAnchorEl(null);
			return;
		}
		setAnchorEl(event.currentTarget);
		event.stopPropagation();
	};

	const renderColoredDay = (
		date: Dayjs,
		_selectedDates: Array<Dayjs | null>,
		pickersDayProps: PickersDayProps<Dayjs>
	) => {
		if (!value) return <PickersDay {...pickersDayProps} />;

		let tag: StatTags | 'OUTSIDE';
		if (prohibitedDates(date)) tag = 'OUTSIDE';
		else {
			const statOnDate = currentHabit.stats.find(stat =>
				date.isSame(dayjs.unix(stat.forDate.seconds), 'day')
			);
			if (!statOnDate) tag = StatTags.EMPTY;
			else tag = statOnDate.tag;
		}

		const pickerDayStyling = tagColorSelector(tag);

		return (
			<PickersDay
				{...pickersDayProps}
				selected={false}
				onClick={event => handleCheckerMenuOpen(event, date)}
				sx={pickerDayStyling}
			/>
		);
	};

	const updatePartialSuccessValue = async (newValue: string) => {
		if (newValue !== currentHabit.partialSuccess) {
			const backendResponse = await editHabit({
				id: currentHabit.id,
				partialSuccess: newValue,
			});
			backendMessageHandler(backendResponse, enqueueSnackbar);
			const modifiedHabits = habits.map(mappedHabit =>
				mappedHabit.id === currentHabit.id
					? { ...mappedHabit, partialSuccess: newValue }
					: mappedHabit
			);
			setHabits(modifiedHabits);
		}
	};
	const updateGoalValue = async (newValue: string) => {
		if (newValue !== currentHabit.goal) {
			const backendResponse = await editHabit({ id: currentHabit.id, goal: newValue });
			backendMessageHandler(backendResponse, enqueueSnackbar);
			const modifiedHabits = habits.map(mappedHabit =>
				mappedHabit.id === currentHabit.id
					? { ...mappedHabit, goal: newValue }
					: mappedHabit
			);
			setHabits(modifiedHabits);
		}
	};

	return (
		<Grid
			container
			direction='column'
			justifyContent={'center'}
			alignItems={'flex-start'}>
			<Divider flexItem />
			<Grid item sx={{ padding: '.5rem 1.5rem' }}>
				<Typography sx={{ color: '#333', fontWeight: 700 }}>
					{currentHabit.title}
				</Typography>
			</Grid>
			<Divider flexItem />
			<Grid item alignSelf={'center'}>
				<LocalizationProvider dateAdapter={AdapterDayjs} maxWidth={335}>
					<StaticDatePicker
						displayStaticWrapperAs='desktop'
						disableHighlightToday
						label='Week picker'
						value={value}
						onChange={value => setValue(value)}
						renderDay={renderColoredDay}
						renderInput={params => <TextField {...params} />}
						inputFormat="'Week of' MMM d"
					/>
					{value && (
						<HabitTagSelector
							selectedDate={value}
							anchorEl={anchorEl}
							setAnchorEl={setAnchorEl}
							habit={currentHabit}
							habits={habits}
							setHabits={setHabits}
						/>
					)}
				</LocalizationProvider>
			</Grid>
			<Divider flexItem />
			<Grid
				item
				sx={{
					padding: '.5rem 2.25rem .5rem 1.5rem',
					width: '100%',
					position: 'relative',
				}}>
				<Box sx={{ display: 'inline', color: '#333', fontWeight: 700 }}>Goal:</Box>
				{currentHabit.goal && currentHabit.goal.length > 0
					? ` ${currentHabit.goal}`
					: ' No value'}
				<IconButton
					sx={theme => ({
						position: 'absolute',
						right: 8,
						top: 10,
						background: alpha('#000', 0.1),
						':hover': {
							background:
								theme.palette.mode === 'dark' ? alpha('#fff', 0.3) : alpha('#000', 0.15),
						},
						padding: '4px',
					})}
					onClick={handleGoalAnchorEl}>
					<EditIcon sx={{ fontSize: '1rem' }} />
				</IconButton>
			</Grid>
			<Divider flexItem />
			<Grid
				item
				sx={{
					padding: '.5rem 2.25rem .5rem 1.5rem',
					width: '100%',
					position: 'relative',
					marginBottom: 6,
				}}>
				<Box sx={{ display: 'inline', color: '#333', fontWeight: 700 }}>
					Partial Success:
				</Box>
				<IconButton
					sx={theme => ({
						position: 'absolute',
						right: 8,
						top: 10,
						background: alpha('#000', 0.1),
						':hover': {
							background:
								theme.palette.mode === 'dark' ? alpha('#fff', 0.3) : alpha('#000', 0.15),
						},
						padding: '4px',
					})}
					onClick={handlePartialSuccessAnchorEl}>
					<EditIcon sx={{ fontSize: '1rem' }} />
				</IconButton>
				{currentHabit.partialSuccess && currentHabit.partialSuccess.length > 0
					? ` ${currentHabit.partialSuccess}`
					: ' No value'}
			</Grid>
			<TextChangePopover
				valueTitle={'partial success description'}
				popoverAnchorEl={partialSuccessAnchorEl}
				handlePopoverAnchorEl={handlePartialSuccessAnchorEl}
				initialValue={currentHabit.partialSuccess}
				saveChanges={updatePartialSuccessValue}
			/>
			<TextChangePopover
				valueTitle={'goal description'}
				popoverAnchorEl={goalAnchorEl}
				handlePopoverAnchorEl={handleGoalAnchorEl}
				initialValue={currentHabit.goal}
				saveChanges={updateGoalValue}
			/>
		</Grid>
	);
};

export default HabitCalendar;
