import {
	Button,
	Grid,
	Typography,
	useMediaQuery,
	Box,
	Stack,
	Skeleton,
	Drawer,
	IconButton,
	Dialog,
} from '@mui/material';
import { HabitData, Stages } from '../types';
import { useState, useEffect } from 'react';
import CreateHabitDialog from '../components/CreateHabitDialog';
import theme from '../theme';
import UserContainer, {
	appBarHeight,
	menuDrawerWidth,
} from '../components/UserContainer';
import { getHabitData } from '../firebase.config';
import { useSnackbar } from 'notistack';
import backendMessageHandler from '../utils/backendMessageHandler';
import recentMissingDataExists from '../utils/recentMissingDataExists';
import HabitStageSelector from '../components/HabitStageSelector';
import { useNavigate, useParams } from 'react-router-dom';
import { useHabitOptions } from '../habitOptionsContext';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import HabitCalendar from '../components/HabitCalendar';

import Habit from '../components/Habit';

export const sidebarWidth = 340;

const Habits: React.FC = (): JSX.Element => {
	const pc = useMediaQuery(theme.breakpoints.up('lg'));
	const tablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
	const phone = useMediaQuery(theme.breakpoints.down('sm'));

	const [habitsLoading, setHabitsLoading] = useState<boolean>(true);

	const [habits, setHabits] = useState<[] | Array<HabitData>>([]);
	const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { id } = useParams();
	const { state, dispatch } = useHabitOptions();

	useEffect(() => {
		const fetchHabits = async () => {
			setHabitsLoading(true);
			const backendResponse = await getHabitData();
			let documentData = backendMessageHandler(backendResponse, enqueueSnackbar);
			if (!documentData) documentData = [];
			if (!Array.isArray(documentData)) documentData = [documentData];
			documentData = recentMissingDataExists(documentData);

			setHabits(documentData);
			if (documentData.length > 0) {
				if (id) {
					// Searching for the id you requested in the url parameters for matching document
					const queriedHabit = documentData.find(habit => habit.id === id);
					// If matching document does not exist show default habit list
					if (!queriedHabit) {
						navigate(`/habits/`, { replace: true });
						enqueueSnackbar('This habit does not exist or was deleted');
						// If queried habit exists at the stage you're currently at, navigate to the habit
					} else if (queriedHabit && state.selectedStage === queriedHabit.stage) {
						navigate(`/habits/${queriedHabit.id}`, { replace: true });
						// If queried habit exists but at a different stage, move to that stage and then navigate to habit
					} else if (queriedHabit && state.selectedStage !== queriedHabit.stage) {
						navigate(`/habits/${queriedHabit.id}`, { replace: true });
						dispatch({ type: 'SELECT_STAGE', payload: queriedHabit.stage });
					}
				} else {
					if (phone) navigate(`/habits/`, { replace: true });
					if (tablet || pc) {
						const habitsAtStage = [
							...documentData.filter(habit => habit.stage === state.selectedStage),
						];
						// if no habit exists at the stage you have selected or your on phone show default habit list
						if (habitsAtStage.length === 0) navigate(`/habits/`, { replace: true });
						// If habits exist on stage, get first habit's id and navigate to it (default function)
						const firstHabitId = habitsAtStage[0].id;
						if (firstHabitId) navigate(`/habits/${firstHabitId}`, { replace: true });
					}
				}
			} else {
				navigate(`/habits/`, { replace: true });
			}
			setHabitsLoading(false);
		};
		fetchHabits();
		//eslint-disable-next-line
	}, []);

	const mainContentStyling = {
		height: '100%',
		overflowY: 'auto',

		...(phone && { padding: theme.spacing(10, 0, 0, 0) }),
		...(tablet && { padding: theme.spacing(12, 2, 0, 2) }),
		...(pc && { padding: theme.spacing(15, 6, 0, 6) }),

		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		// Transition persistent right sidebar drawer to shift habit content on computer and tablet
		...((tablet || pc) && {
			marginRight: `-${sidebarWidth}px`,
			...(id && {
				transition: theme.transitions.create('margin', {
					easing: theme.transitions.easing.easeOut,
					duration: theme.transitions.duration.enteringScreen,
				}),
				marginRight: 0,
			}),
		}),

		// Transition persistent left menu drawer on computer
		...(pc && {
			marginLeft: `-${menuDrawerWidth}px`,
			...(state.menuDrawerOpen && {
				transition: theme.transitions.create('margin', {
					easing: theme.transitions.easing.easeOut,
					duration: theme.transitions.duration.enteringScreen,
				}),
				marginLeft: 0,
			}),
		}),
	};

	const closeSidebar = () => navigate(`/habits/`, { replace: true });

	const filterByStage = (habit: HabitData) => habit.stage === state.selectedStage;
	const handleOpenCreateDialog = () => setOpenCreateDialog(true);
	const filteredHabits = habits.filter(filterByStage);
	const calendarOpen = Boolean(id);

	return (
		<UserContainer habits={habits} setHabits={setHabits}>
			<Grid container sx={mainContentStyling} alignItems='flex-start'>
				<Grid container direction='column'>
					<Grid
						container
						direction='row'
						gap={1}
						justifyContent={'space-between'}
						alignItems={'center'}
						marginBottom={0.2}>
						<Grid item>
							<Typography marginLeft={0.5} variant='h5' fontWeight={'bold'}>
								Habits
							</Typography>
						</Grid>
						<Grid item>
							<Grid
								container
								justifyContent={'flex-end'}
								alignItems={'flex-end'}
								gap={1}
								padding={theme.spacing(0, 0.5, 0.5, 0)}>
								<Grid item>
									<HabitStageSelector habits={habits} />
								</Grid>
								<Grid item>
									<Button
										variant='contained'
										color='variable'
										sx={{ fontWeight: '600', padding: theme.spacing(0.5, 3) }}
										onClick={handleOpenCreateDialog}>
										New
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{habitsLoading ? (
						<Stack spacing={1}>
							<Skeleton variant='rectangular' width={'auto'} height={60} />
							<Skeleton variant='rectangular' width={'auto'} height={60} />
							<Skeleton variant='rectangular' width={'auto'} height={60} />
							<Skeleton variant='rectangular' width={'auto'} height={60} />
							<Skeleton variant='rectangular' width={'auto'} height={60} />
							<Skeleton variant='rectangular' width={'auto'} height={60} />
							<Skeleton variant='rectangular' width={'auto'} height={60} />
							<Skeleton variant='rectangular' width={'auto'} height={60} />
						</Stack>
					) : (
						<>
							{filteredHabits.length !== 0 ? (
								filteredHabits.map(habitData => (
									<Habit
										habit={habitData}
										habits={habits}
										setHabits={setHabits}
										key={habitData.id}
									/>
								))
							) : (
								<Box sx={{ marginTop: 2 }}>
									{state.selectedStage === Stages.UPCOMING &&
										`To create your first upcoming habit, use the "New" button & add a title...`}
									{state.selectedStage === Stages.TRACKING &&
										`To create your first habit, use the "New" button & add a title...`}
									{state.selectedStage === Stages.COMPLETED &&
										`Here is where your completed habits will go. Use the "New" button to create a new habit...`}
								</Box>
							)}
						</>
					)}
					<CreateHabitDialog
						open={openCreateDialog}
						setOpen={setOpenCreateDialog}
						habits={habits}
						setHabits={setHabits}
					/>
				</Grid>
			</Grid>
			{pc || tablet ? (
				<Drawer
					variant={'persistent'}
					open={calendarOpen}
					onClose={closeSidebar}
					sx={{
						width: sidebarWidth,
						flexShrink: 0,
						'& .MuiDrawer-paper': {
							width: sidebarWidth,
							boxSizing: 'border-box',
						},
					}}
					ModalProps={{ keepMounted: true }}
					PaperProps={{ elevation: 3 }}
					anchor='right'>
					<IconButton
						onClick={closeSidebar}
						sx={{ height: appBarHeight, width: appBarHeight }}
						aria-label='close calendar drawer'>
						<ChevronRightIcon fontSize='medium' />
					</IconButton>
					<HabitCalendar habits={habits} setHabits={setHabits} />
				</Drawer>
			) : (
				<Dialog open={calendarOpen} fullScreen onClose={closeSidebar}>
					<IconButton
						onClick={closeSidebar}
						sx={{ height: appBarHeight, width: appBarHeight }}
						aria-label='close calendar drawer'>
						<CloseIcon />
					</IconButton>
					<HabitCalendar habits={habits} setHabits={setHabits} />
				</Dialog>
			)}
		</UserContainer>
	);
};

export default Habits;
