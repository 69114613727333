export const emailValid = (email: string) => {
	const emailFormat =
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	const isFormatValid = emailFormat.test(String(email).toLowerCase());
	return isFormatValid;
};

export const passwordValid = (password: string) => {
	const isFormatValid = password.length >= 8 && password.length < 100;
	return isFormatValid;
};
