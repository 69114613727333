import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { deleteHabit } from '../firebase.config';
import theme from '../theme';
import { HabitData } from '../types';
import backendMessageHandler from '../utils/backendMessageHandler';
import { useNavigate, useParams } from 'react-router-dom';

interface DialogProps {
	habit: HabitData;
	habits: HabitData[];
	setHabits: React.Dispatch<React.SetStateAction<HabitData[]>>;
	closeDialog: () => void;
}

export default function DeleteHabitDialog({
	habit,
	habits,
	setHabits,
	closeDialog,
}: DialogProps) {
	const navigate = useNavigate();
	const { id } = useParams();
	const { enqueueSnackbar } = useSnackbar();

	const handleClose = (event: React.MouseEvent<HTMLElement>) => {
		closeDialog();
		event.stopPropagation();
	};

	const deleteHabitHandler = async (event: React.MouseEvent<HTMLElement>) => {
		const deletedHabitNewList = [
			...habits.filter(mappedHabit => mappedHabit.id !== habit.id),
		];

		const backendResponse = await deleteHabit(habit.id);
		backendMessageHandler(backendResponse, enqueueSnackbar);

		// If the deleted habit is selected, unselect it
		if (id === habit.id) navigate(`/habits/`, { replace: true });

		setHabits(deletedHabitNewList);
		return handleClose(event);
	};

	const buttonStyling = {
		fontSize: 13,
		fontWeight: '600',
	};

	return (
		<Dialog maxWidth={'xs'} open onClose={handleClose}>
			<Box
				sx={{
					width: '80%',
					display: 'grid',
					direction: 'column',
					alignItems: 'stretch',
					margin: theme.spacing(3, 'auto'),
					padding: 0.3,
					gap: 1,
				}}>
				<Typography sx={{ margin: theme.spacing(0, 0, 1.5, 0) }}>
					Are you sure you want to delete this habit?
				</Typography>
				<Button
					sx={buttonStyling}
					variant='outlined'
					color='secondary'
					onClick={deleteHabitHandler}>
					Yes. Delete This Habit
				</Button>
				<Button
					sx={buttonStyling}
					variant='outlined'
					color='primary'
					onClick={handleClose}>
					Cancel
				</Button>
			</Box>
		</Dialog>
	);
}
