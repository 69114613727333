import dayjs from 'dayjs';
import { HabitData } from '../types';

// The goal of this function is to display, in the UI, the recent (at most 2 days') gaps in the user's habit stat selection.
// This occurs only if no information (aka tag or stats) was added on those days. So here, that's what we're checking.
export const isToday = (dateInSeconds: number) =>
	dayjs().isSame(dateInSeconds * 1000, 'day');
export const isYesterday = (dateInSeconds: number) =>
	dayjs()
		.add(-1, 'day')
		.isSame(dateInSeconds * 1000, 'day');

const recentMissingDataExists = (habits: HabitData[] | []): HabitData[] | [] => {
	if (habits.length === 0) return [];

	const modifiedHabits = habits.map(mappedHabit => {
		const todaysStat = mappedHabit.stats.find(stat => isToday(stat.forDate.seconds));
		const todaysStatExists = !!todaysStat;
		// if a stat was or wasn't added today
		if (!todaysStatExists) mappedHabit.todayTag = undefined;
		if (todaysStatExists) mappedHabit.todayTag = todaysStat.tag;
		// if created today ignore the rest
		const habitCreatedToday = isToday(mappedHabit.createdAt.seconds);
		if (habitCreatedToday) return mappedHabit;

		const yesterdaysStat = mappedHabit.stats.find(stat =>
			isYesterday(stat.forDate.seconds)
		);
		const yesterdaysStatExists = !!yesterdaysStat;
		// if not created today but yesterday's stat does not exist
		if (!habitCreatedToday && !yesterdaysStatExists) mappedHabit.yesterdayTag = undefined;
		if (yesterdaysStatExists) mappedHabit.yesterdayTag = yesterdaysStat.tag;
		return mappedHabit;
	});

	return modifiedHabits;
};

export default recentMissingDataExists;
