import { Grid, IconButton, Typography } from '@mui/material';
import { HabitData } from '../types';
import theme from '../theme';
import HabitTagButton from './HabitTagButton';
import HabitSettingsMenu from './HabitSettingsMenu';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';

export interface HabitProps {
	habit: HabitData;
	habits: HabitData[];
	setHabits: React.Dispatch<React.SetStateAction<HabitData[]>>;
}

const Habit: React.FC<HabitProps> = ({ habit, habits, setHabits }): JSX.Element => {
	const navigate = useNavigate();
	const location = useLocation();
	const { id } = useParams();

	const [contextMenu, setContextMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const handleContextMenu = (event: React.MouseEvent | null) => {
		if (event === null) setContextMenu(null);
		else {
			const newContextValue =
				contextMenu === null
					? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 }
					: null;
			setContextMenu(newContextValue);
			event.stopPropagation();
			event.preventDefault();
		}
	};

	const habitContainerStyling = (habitId: string) => {
		return {
			userSelect: 'none',
			'&:hover': {
				cursor: 'pointer',
				background:
					id !== habitId ? theme.palette.divider : theme.palette.action.selected,
			},
			padding: 1,
			borderTop: `1px solid ${theme.palette.divider}`,
			'&:last-child': {
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			background:
				id === habitId ? theme.palette.action.selected : theme.palette.background.default,
			transition: theme.transitions.create(['background'], {
				easing: theme.transitions.easing.sharp,
				duration: 50,
			}),
		};
	};

	const handleCurrentHabitSelection = (
		event: React.MouseEvent<HTMLElement>,
		id: string
	) => {
		const endpointSameAsCurrentLocation = location.pathname === `/habits/${id}`;
		if (!endpointSameAsCurrentLocation) navigate(`/habits/${id}`, { replace: true });
		else navigate(`/habits/`, { replace: true });
	};

	return (
		<>
			<Grid
				onClick={(event: React.MouseEvent<HTMLElement>) =>
					handleCurrentHabitSelection(event, habit.id)
				}
				onContextMenu={handleContextMenu}
				sx={habitContainerStyling(habit.id)}
				container
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				wrap='nowrap'>
				<Grid item xs>
					<Typography variant='body1'>{habit.title}</Typography>
				</Grid>
				<Grid item xs='auto'>
					<IconButton
						aria-label='settings menu'
						aria-controls='chosen habit'
						aria-haspopup='true'
						onClick={handleContextMenu}>
						<MoreVertIcon />
					</IconButton>
				</Grid>
				<Grid item xs='auto'>
					<HabitTagButton habit={habit} habits={habits} setHabits={setHabits} />
				</Grid>
			</Grid>
			{contextMenu !== null && (
				<HabitSettingsMenu
					habit={habit}
					habits={habits}
					setHabits={setHabits}
					contextMenu={contextMenu}
					setContextMenu={handleContextMenu}
				/>
			)}
		</>
	);
};

export default Habit;
