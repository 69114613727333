import { Popover, Grid, TextField, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useState, useEffect, useRef } from 'react';

interface TextChangePopoverProps {
	valueTitle: string;
	popoverAnchorEl: {
		mouseX: number;
		mouseY: number;
	} | null;
	handlePopoverAnchorEl: (event: React.MouseEvent | null) => void;
	initialValue: string | undefined;
	saveChanges: (value: string) => Promise<void>;
	onClose?: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
}

const TextChangePopover: React.FC<TextChangePopoverProps> = ({
	valueTitle,
	popoverAnchorEl,
	handlePopoverAnchorEl,
	onClose = () => handlePopoverAnchorEl(null),
	initialValue = '',
	saveChanges,
}) => {
	const textField = useRef<HTMLDivElement>(null);

	const [value, setValue] = useState(initialValue);

	// Focus on text input when the popover is opened
	useEffect(() => {
		if (Boolean(popoverAnchorEl) && textField.current) textField.current.focus();
	}, [popoverAnchorEl]);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	const handleSaveChanges = async () => {
		let finalValue = value;
		if (value === undefined || value.length === 0 || typeof value !== 'string')
			finalValue = 'Untitled';
		handlePopoverAnchorEl(null);
		await saveChanges(finalValue);
	};

	const changeValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setValue(e.target.value);
	};

	const idValue = `edit-${valueTitle.replace(/\s+/g, '-').toLowerCase()}`;

	return (
		<Popover
			id={`${idValue}-container`}
			open={Boolean(popoverAnchorEl)}
			onClose={(event, reason) => {
				setValue(initialValue);
				onClose(event, reason);
			}}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			anchorReference='anchorPosition'
			anchorPosition={
				popoverAnchorEl !== null
					? { top: popoverAnchorEl.mouseY, left: popoverAnchorEl.mouseX }
					: undefined
			}
			PaperProps={{ sx: { padding: '1rem 1rem 0 1rem', minWidth: '200px' } }}>
			<Grid container justifyContent={'flex-end'}>
				<TextField
					inputRef={textField}
					onFocus={e =>
						e.currentTarget.setSelectionRange(
							e.currentTarget.value.length,
							e.currentTarget.value.length
						)
					}
					id={`${idValue}-input`}
					label={`Edit ${valueTitle}`}
					type='name'
					fullWidth
					variant='standard'
					multiline
					value={value}
					onChange={changeValue}
				/>
				<Button
					sx={{ padding: 1 }}
					size='medium'
					variant='text'
					aria-label='settings menu'
					aria-controls='chosen habit'
					aria-haspopup='true'
					onClick={handleSaveChanges}
					startIcon={<CheckCircleOutlineIcon />}>
					Save Changes
				</Button>
			</Grid>
		</Popover>
	);
};

export default TextChangePopover;
