import { Typography, Button } from '@mui/material';
import { useState } from 'react';
import { HabitData, StatTags, Weekdays } from '../types';
import theme, { tagColorSelector } from '../theme';
import HabitTagSelector from './HabitTagSelector';
import dayjs, { Dayjs } from 'dayjs';
import { isToday } from '../utils/recentMissingDataExists';

export interface HabitTagButtonProps {
	habit: HabitData;
	habits: HabitData[];
	setHabits: React.Dispatch<React.SetStateAction<HabitData[]>>;
}

const HabitTagButton: React.FC<HabitTagButtonProps> = ({
	habit,
	habits,
	setHabits,
}): JSX.Element => {
	const [checkerAnchorEl, setCheckerAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

	const todayTag = habit.todayTag;
	const yesterdayTag = habit.yesterdayTag;
	const isCreatedToday = isToday(habit.createdAt.seconds);
	const todayTagExists = !!todayTag;
	const yesterdayTagExists = !!yesterdayTag;

	const handleCheckerMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setCheckerAnchorEl(event.currentTarget);
		const setYesterday = dayjs().add(-1, 'day').startOf('day');
		const setToday = dayjs().startOf('day');

		setSelectedDate(
			!yesterdayTagExists ? (isCreatedToday ? setToday : setYesterday) : setToday
		);
		event.stopPropagation();
	};

	const tagType =
		todayTagExists && (yesterdayTagExists || isCreatedToday) ? todayTag : StatTags.EMPTY;

	const habitStatTagColor = tagColorSelector(tagType);

	const todayWeekday = Weekdays[dayjs().get('d')];
	const yesterdayWeekday = Weekdays[dayjs().add(-1, 'day').get('d')];

	return (
		<>
			<Button
				variant={yesterdayTagExists || todayTagExists ? 'outlined' : 'contained'}
				sx={{
					minWidth: 55,
					width: 55,
					height: 55,
					padding: theme.spacing(0),
					...habitStatTagColor,
				}}
				aria-label='tag selection'
				aria-controls='chosen habit'
				aria-haspopup='true'
				onClick={handleCheckerMenuOpen}>
				<Typography variant='body1'>
					{yesterdayTagExists || isCreatedToday ? todayWeekday : yesterdayWeekday}
				</Typography>
			</Button>
			{checkerAnchorEl !== null && (
				<HabitTagSelector
					habit={habit}
					habits={habits}
					setHabits={setHabits}
					selectedDate={selectedDate}
					anchorEl={checkerAnchorEl}
					setAnchorEl={setCheckerAnchorEl}
				/>
			)}
		</>
	);
};

export default HabitTagButton;
