import Error from '@mui/icons-material/Error';
import { Box, Typography } from '@mui/material';
import theme from '../theme';

interface ErrorMessageProps {
	errorTriggered: boolean;
	message: string;
}
export const InlineErrorMessage: React.FC<ErrorMessageProps> = ({
	errorTriggered,
	message,
}) => {
	return errorTriggered ? (
		<Box sx={{ display: 'flex', columnGap: 1 }}>
			<Error
				sx={{
					fontSize: 'medium',
					color: theme.palette.error.main,
					alignSelf: 'center',
					marginBottom: 0.4,
				}}
				fontSize='small'
			/>
			<Typography
				sx={{
					fontWeight: 500,
					fontSize: 12,
					color: theme.palette.error.main,
					alignSelf: 'center',
				}}
				variant='subtitle2'>
				{message}
			</Typography>
		</Box>
	) : null;
};
